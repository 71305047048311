import React, { useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const App: React.FC = () => {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAQysLdfnBMLT6l4LIhyZUG0neGFS305sM',
      authDomain: 'stl-p-services.firebaseapp.com',
      projectId: 'stl-p-services',
      storageBucket: 'stl-p-services.appspot.com',
      messagingSenderId: '716559724965',
      appId: '1:716559724965:web:733a7c4001d1ec6697b84c',
      measurementId: 'G-01C0VMG991',
    }

    const app = initializeApp(firebaseConfig)
    getAnalytics(app)
  }, [])

  return (
    <div className="bg-accent min-h-screen text-white flex flex-col p-2">
      <header className="p-4">
        <img src="/syentist_logo_landscape.png" alt="Logo" className="h-12" />
      </header>
      <main className="flex flex-col items-center justify-center h-full py-12 space-y-12">
        <h1 className="text-center text-4xl font-bold mb-6">
          We build tools that empower founders just like ourselves
        </h1>
        <a
          href="https://cogo.tools"
          target="_blank"
          rel="noreferrer"
          className="relative px-10 p-8 bg-black bg-opacity-5 rounded-2xl hover:bg-opacity-20 transition-all duration-200 ease-in-out h-[14vh] flex items-center justify-center cursor-pointer"
        >
          <img src="/cogo-logo.png" alt="Cogo" className="h-full w-full" />
        </a>
      </main>
      <footer className="mt-auto py-4 text-center text-gray-500 bottom-0 sticky">
        © Copyright 2023 Syentist Lab Inc. All rights reserved.
      </footer>
    </div>
  )
}

export default App
